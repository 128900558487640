@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.app__results {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 2rem;
  background-color: #f9f9f9;

  .head-text {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  .slick-list {
    padding: 2rem 0;
  }

  .slick-slide > div {
    margin: 0 1rem;
  }

  .slick-dots {
    bottom: -2rem;

    li button:before {
      font-size: 0.8rem;
      color: #000;
    }

    li.slick-active button:before {
      color: #1a03af39;
    }
  }

  .app__results-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #000;
    cursor: pointer;
    z-index: 1;

    &.app__results-arrow-left {
      left: -1rem;
    }

    &.app__results-arrow-right {
      right: -1rem;
    }
  }

  .app__results-item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f0f0f0;
    }

    img {
      width: 100%;
      height: 300px;
      border-radius: 0.5rem;
      object-fit: cover;
    }

    h2 {
      margin-top: 1rem;
      font-size: 1.5rem;
      text-align: center;
    }

    p {
      margin-top: 0.5rem;
      text-align: center;
    }

    .double-click-text {
      margin-top: 0.5rem;
      font-size: 0.9rem;
      color: #888;
    }

    @media screen and (max-width: 600px) {
      img {
        height: 350px;
      }
    }
  }
}

.app__results-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 100%;
  max-height: 100%;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  .modal-image {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: contain;
  }

  @media screen and (max-width: 600px) {
    position: fixed;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 30px;

    .modal-image {
      max-height: 100%;
    }
  }
}

.app__results-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.app__results-modal-close {
  align-self: flex-end;
  background-color: transparent;
  border: none;
  color: #000;
  font-size: 1.2rem;
  cursor: pointer;
}