.app__social {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app__social a {
  color: #286fd9; 
  text-decoration: none;
  font-size: 24px; 
  margin: 1rem 2rem; 
}

